import { ThemeProvider } from '@material-ui/core/styles';
import { StaticQuery, graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';

import theme from '../theme';

import Footer from './Footer';
import TopToolbar from './TopToolbar';

export type LayoutProps = { children: React.ReactNode };

const Layout: FunctionComponent<LayoutProps> = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <TopToolbar menuLinks={data.site.siteMetadata.menuLinks} />
        {children}
        <Footer menuLinks={data.site.siteMetadata.menuLinks} />
      </ThemeProvider>
    )}
  />
);

Layout.displayName = 'Layout';

export default Layout;
