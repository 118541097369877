import React from 'react';

import linkedinlogo from '../../images/linkedin_logo.png';

const LinkedInIcon = () => (
  <a href="https://www.linkedin.com/company/treet/">
    <img width="38" height="38" alt="Facebook" src={linkedinlogo} />
  </a>
);

export default LinkedInIcon;
