import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(theme => ({
  feedbackText: {
    fontWeight: 400,
    fontSize: theme.spacing(2.25),
    marginBottom: theme.spacing(0.5),
  },
}));

export type TestimonialItemProps = {
  text: string;
};

const TestimonialItem: FunctionComponent<TestimonialItemProps> = props => {
  const classes = useStyles();

  return <Typography className={classes.feedbackText}>"{props.text}"</Typography>;
};

export default TestimonialItem;
