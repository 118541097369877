import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export type BodyTextProps = {
  styling: 'light' | 'dark';
  size: 'normal' | 'small';
};

const useStyles = makeStyles(theme => ({
  dark: {
    color: theme.palette.treetDarkGray.main,
    marginBottom: theme.spacing(0),
  },
  light: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(0),
  },
}));

const BodyText: React.FunctionComponent<BodyTextProps> = props => {
  const classes = useStyles();
  return (
    <Typography component="p" variant={props.size === 'normal' ? 'body1' : 'body2'} className={classes[props.styling]}>
      {props.children}
    </Typography>
  );
};

export default BodyText;
