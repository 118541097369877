import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'gatsby';

// mobile-menu
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

//images
import treetLogoImage from '../images/treet-logo-color.svg';
import { PharmacySearchButton } from './elements';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: '#ffffff',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: '100%',
      flexShrink: 0,
    },
    drawerPaper: {
      width: '100%',
      background: 'transparent',
    },
    drawerHeader: {
      position: 'absolute',
      left: 0,
      right: '80.27%',
      top: 0,
      bottom: '-0.31%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.6)',
      textAlign: 'right',
      padding: theme.spacing(1),
      color: 'white',
    },
    drawPaperItems: {
      position: 'absolute',
      left: '19.73%',
      right: 0,
      top: 0,
      bottom: '-0.31%',
      height: '100%',
      backgroundColor: 'white',
      boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
    },
    logoImage: {
      height: 96,
    },
    linksContainer: {
      margin: 0,
      padding: 0,
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    linkItem: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    linkDecorations: {
      fontFamily: 'Cera Round Pro',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '15px',
      lineHeight: '123%',
      letterSpacing: '0.25px',
      textTransform: 'uppercase',
    },
    menu: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    burgerMenu: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      color: theme.palette.treetDarkGray.main,
      textAlign: 'right',
    },
    mobileMenuCloseButton: {
      width: '17.5px',
    },
    mobileMenuItem: {
      padding: theme.spacing(2),
      width: '100%',
    },
  }),
);

export type TopToolbarProps = { menuLinks: Array<any> };

const TopToolbar: React.FunctionComponent<TopToolbarProps> = ({ menuLinks }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar position="sticky" elevation={0} className={classes.appBar}>
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Link to="/">
                <img src={treetLogoImage} alt="Treet" className={classes.logoImage} />
              </Link>
            </Grid>
            <Grid item xs={10} className={classes.menu}>
              <nav>
                <ul className={classes.linksContainer}>
                  {menuLinks.map(link =>
                    link.name === 'Kirjaudu' ? (
                      <li key={link.name} className={classes.linkItem}>
                        <PharmacySearchButton isCapitalizedTitle={true} />
                      </li>
                    ) : (
                      <li key={link.name} className={classes.linkItem}>
                        <Link
                          className={classes.linkDecorations}
                          to={link.link}
                          style={{ textDecoration: 'none', color: '#202944' }}
                        >
                          {link.name}
                        </Link>
                      </li>
                    ),
                  )}
                </ul>
              </nav>
            </Grid>
            <Grid item xs={10} className={classes.burgerMenu}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} onClick={handleDrawerClose}>
          <CloseIcon className={classes.mobileMenuCloseButton} />
        </div>
        <List className={classes.drawPaperItems}>
          {menuLinks.map(link =>
            link.name === 'Kirjaudu' ? (
              <ListItem button key={link.name}>
                <a
                  className={[classes.linkDecorations, classes.mobileMenuItem].join(' ')}
                  href={link.link}
                  onClick={handleDrawerClose}
                  style={{ textDecoration: 'none' }}
                >
                  {link.name}
                </a>
              </ListItem>
            ) : (
              <ListItem button key={link.name}>
                <Link
                  className={[classes.linkDecorations, classes.mobileMenuItem].join(' ')}
                  to={link.link}
                  onClick={handleDrawerClose}
                  style={{ textDecoration: 'none' }}
                >
                  {link.name}
                </Link>
              </ListItem>
            ),
          )}
        </List>
      </Drawer>
    </>
  );
};

export default TopToolbar;
