import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import treetLogoImage from '../images/treet-logo-color.svg';
import { AppDownloadLinks, FacebookIcon, InstagramIcon, LinkedInIcon } from './icon';
import { Header, BodyText, PharmacySearchButton } from './elements';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  grid: {
    width: '100%',
    height: 'auto',
    background: '#F8F9FA',
  },
  contactPageLink: {
    color: '#202944',
    textDecoration: 'none',
    '&:hover': {
      color: ' #58C05C',
      textDecoration: 'underline',
    },
    '&:active': {
      color: '#58C05C',
      textDecoration: 'underline',
    },
  },
  container: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(3),
  },
  linkDecorations: {
    textDecoration: 'none',
    color: 'rgba(32, 41, 68, 0.6)',
    '&:hover': {
      color: ' #58C05C',
      textDecoration: 'underline',
    },
    '&:active': {
      color: '#58C05C',
      textDecoration: 'underline',
    },
  },
  rowPadding: {
    padding: theme.spacing(2, 0),
  },
  lastRow: {
    flexDirection: 'row',
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      paddingTop: theme.spacing(3),
    },
  },
  fimeaLogo: {
    height: 'auto',
    maxWidth: 150,
  },
  copyrightAndTermsTexts: {
    color: 'rgba(32, 41, 68, 0.6)',
  },
}));

export type FooterProps = { menuLinks: Array<any> };

const FooterComponent: React.FunctionComponent<FooterProps> = ({ menuLinks }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.grid}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container alignItems="flex-start">
          <Grid container item xs={12} sm={12}>
            <Grid item xs={12}>
              <img height="96" src={treetLogoImage} alt="Treet" />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={3} className={classes.rowPadding}>
            <Grid item xs={12}>
              <Header styling="dark" variant="h4" center={false}>
                Lataa sovellus
              </Header>
            </Grid>
            <Grid item xs={12}>
              <AppDownloadLinks />
            </Grid>
            <Grid item xs={12}>
              {menuLinks.map(
                link =>
                  link.name === 'Kirjaudu' && (
                    <Box mt={2} key={link.name}>
                      <PharmacySearchButton isCapitalizedTitle={true} startIcon={<ArrowForwardOutlinedIcon />} />
                    </Box>
                  ),
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={3} className={classes.rowPadding}>
            <Grid item xs={12}>
              <Header styling="dark" variant="h4" center={false}>
                Treet
              </Header>
            </Grid>
            <Grid item xs={12}>
              <BodyText styling="dark" size="small">
                <Link className={classes.contactPageLink} to="/ajankohtaista" style={{ textDecoration: 'none' }}>
                  Ajankohtaista
                </Link>
              </BodyText>
            </Grid>
            <Grid item xs={12}>
              <BodyText styling="dark" size="small">
                <Link className={classes.contactPageLink} to="/ohjeet" style={{ textDecoration: 'none' }}>
                  Ohjeet
                </Link>
              </BodyText>
            </Grid>
            <Grid item xs={12}>
              <BodyText styling="dark" size="small">
                <Link className={classes.contactPageLink} to="/meista" style={{ textDecoration: 'none' }}>
                  Yhteystiedot
                </Link>
              </BodyText>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={3} className={classes.rowPadding}>
            <Grid item xs={12}>
              <Header styling="dark" variant="h4" center={false}>
                Yhteistyössä
              </Header>
            </Grid>
            <Grid item xs={12}>
              <BodyText styling="dark" size="small">
                <Link
                  className={classes.contactPageLink}
                  to="/ajankohtaista/20-04-2022"
                  style={{ textDecoration: 'none' }}
                >
                  Etälääkäri
                </Link>
              </BodyText>
            </Grid>
            <Grid item xs={12}>
              <BodyText styling="dark" size="small">
                <Link
                  className={classes.contactPageLink}
                  to="/ajankohtaista/01-03-2023"
                  style={{ textDecoration: 'none' }}
                >
                  Allergiahoito
                </Link>
              </BodyText>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={3} className={classes.rowPadding}></Grid>
          <Grid container item xs={12} className={classes.lastRow}>
            <Grid item xs={12} sm={3}>
              <BodyText styling="dark" size="small">
                <span className={classes.copyrightAndTermsTexts}>© Treet 2022</span>
              </BodyText>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <BodyText styling="dark" size="small">
                  <a
                    href="https://api.treet.fi/static/terms/privacy_statement_fi.html"
                    className={classes.linkDecorations}
                    style={{ paddingRight: '5px', textDecoration: 'none' }}
                  >
                    Tietosuojaseloste
                  </a>
                </BodyText>{' '}
                <span className={classes.copyrightAndTermsTexts}>|</span>{' '}
                <BodyText styling="dark" size="small">
                  <a
                    href="https://api.treet.fi/static/terms/terms_of_use_fi.html"
                    className={classes.linkDecorations}
                    style={{ paddingLeft: '5px', textDecoration: 'none' }}
                  >
                    Käyttöehdot
                  </a>
                </BodyText>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
            <Grid container item xs={12} sm={3}>
              <Box display="flex" justifyContent="flex-start">
                <FacebookIcon />
                <InstagramIcon />
                <LinkedInIcon />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default FooterComponent;
