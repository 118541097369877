import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  style: {
    fontFamily: 'Cera Round Pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: theme.spacing(7),
    lineHeight: '123%',
    color: '#FFFFFF',
    marginBottom: 0,
  },
}));

const BigNumber: React.FunctionComponent = props => {
  const classes = useStyles();
  return (
    <Typography component="p" className={classes.style}>
      {props.children}
    </Typography>
  );
};

export default BigNumber;
