import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    fontFamily: "'Cera Round Pro', sans-serif",
  },
  palette: {
    treetDarkGreen: {
      main: '#60D068',
    },
    treetLightGreen: {
      main: '#EBFAEC',
    },
    treetDarkGray: {
      main: '#202944',
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: 'Cera Round Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '45px',
        lineHeight: '123%',
        color: '#202944',
        marginBottom: '0.5em',
      },
      h2: {
        fontFamily: 'Cera Round Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '123%',
        color: '#202944',
        marginBottom: '0.67em',
      },
      h3: {
        fontFamily: 'Cera Round Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '123%',
        color: '#202944',
        marginTop: '0.5em',
        marginBottom: '0.67em',
      },
      h4: {
        fontFamily: 'Cera Round Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '22px',
        lineHeight: '123%',
        color: '#202944',
        marginTop: '1em',
        marginBottom: '0.67em',
      },
      h5: {
        fontFamily: 'Cera Round Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '150%',
        letterSpacing: '0.1px',
        color: '#202944',
        marginTop: '1.33em',
        marginBottom: '1em',
      },
      h6: {
        fontFamily: 'Cera Round Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: '#202944',
        marginTop: '1.67em',
        marginBottom: '1.33em',
      },
      body1: {
        fontFamily: 'Cera Round Pro',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '167%',
        letterSpacing: '0.3px',
        color: '#202944',
        marginBottom: '1em',
      },
      body2: {
        fontFamily: 'Cera Round Pro',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '167%',
        letterSpacing: '0.3px',
        color: '#202944',
        marginBottom: '1em',
      },
      caption: {
        color: '#00000062',
      },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        position: 'absolute',
        top: '8px',
        right: 0,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0,
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 14,
      },
    },
  },
});
