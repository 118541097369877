import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttonDecoration: {
    color: 'white',
    fontSize: theme.spacing(2),
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#4FAD4F',
    },
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.treetDarkGreen.main,
    height: theme.spacing(6),
  },
}));

export type PharmacySearchButtonProps = {
  isCapitalizedTitle: boolean;
  startIcon?: React.ReactNode;
};

const PharmacySearchButton: FunctionComponent<PharmacySearchButtonProps> = props => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.buttonDecoration}
      href="https://app.treet.fi/pharmacy-search"
      startIcon={props.startIcon}
      style={{
        textDecoration: 'none',
        color: 'white',
        textTransform: props.isCapitalizedTitle ? 'uppercase' : 'inherit',
      }}
    >
      Valitse apteekki
    </Button>
  );
};

export default PharmacySearchButton;
