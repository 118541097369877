import React from 'react';

import facebooklogo from '../../images/facebook_logo.png';

const FaceBookIcon = () => (
  <a href="https://www.facebook.com/treetapteekki/">
    <img width="38" height="38" alt="Facebook" src={facebooklogo} style={{ paddingRight: '15px' }} />
  </a>
);

export default FaceBookIcon;
