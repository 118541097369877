import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export type HeaderProps = {
  styling: 'light' | 'dark';
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  center: boolean;
};

const useStyles = makeStyles(theme => ({
  dark: {
    color: theme.palette.treetDarkGray.main,
  },
  light: {
    color: theme.palette.common.white,
  },
  centerAlign: {
    textAlign: 'center',
    marginBottom: theme.spacing(2.5),
  },
  leftAlign: {
    textAlign: 'left',
    marginBottom: theme.spacing(2.5),
  },
}));

const Header: React.FunctionComponent<HeaderProps> = props => {
  const classes = useStyles();
  return (
    <Typography
      component={props.variant}
      variant={props.variant}
      className={[classes[props.styling], props.center ? classes.centerAlign : classes.leftAlign].join(' ')}
    >
      {props.children}
    </Typography>
  );
};

export default Header;
