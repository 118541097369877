import React from 'react';

import instagramlogo from '../../images/instagram_logo.png';

const InstagramIcon = () => (
  <a href="https://www.instagram.com/treetapteekki/">
    <img width="38" height="38" alt="Facebook" src={instagramlogo} style={{ paddingRight: '15px' }} />
  </a>
);

export default InstagramIcon;
