import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

import appleAppStore from '../../images/apple_store.svg';
import googlePlay from '../../images/google_play.svg';

const useStyles = makeStyles(theme => ({
  logo: {
    height: 48,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 130,
    },
  },
}));

const AppDownloadLinks: React.FunctionComponent<{ isMainImageArea?: boolean }> = ({ isMainImageArea }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection={isMainImageArea ? 'row' : 'column'}>
      <a
        style={isMainImageArea ? { paddingRight: '10px' } : { paddingBottom: '10px' }}
        href="https://play.google.com/store/apps/details?id=com.treet"
      >
        <img className={classes.logo} alt="Google Play" src={googlePlay} />
      </a>
      <a href="https://apps.apple.com/fi/app/treet/id1460592432?l=fi">
        <img className={classes.logo} alt="Apple Store" src={appleAppStore} />
      </a>
    </Box>
  );
};

export default AppDownloadLinks;
